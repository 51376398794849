<template>
  <div>
    <vs-button
        class="rounded-full text-black"
        color="#f8f8f8"
        @click="toggleRoute()"
      >
        <feather-icon icon="ArrowLeftIcon" class="inline-block align-middle"></feather-icon>
        {{ this.$t('Volver') }}
      </vs-button>
      
    <vx-card>
        
    <form @submit.prevent="saveUser()" method="POST" style="padding:20px">
      <h3 class="text-info">{{ this.$t('Información Básica') }}</h3>
      <vs-divider class="mb-10"/>
      <div class="vx-row mt-5">
        <!-- nombres -->
        <div class="vx-col sm:w-1/2">
          <label for="name"><b>{{ this.$t('Nombres completos') }}</b></label>
          <vx-input-group>
            <vs-input
              id="name"
              name="name"
              v-model="user.name"
              :danger="!!validate.nameError"
              val-icon-danger="clear"
              autofocus
              required
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.nameError">{{ validate.nameError }}</p>
        </div>

        <!-- correo electronico -->
        <div class="vx-col sm:w-1/2">
          <label for="email"><b>{{ this.$t('Correo Electrónico') }}</b></label>
          <vx-input-group>
            <vs-input
              id="email"
              name="email"
              type="email"
              :danger="!!validate.emailError"
              val-icon-danger="clear"
              v-model="user.email"
              placeholder="Ej. carlos.cardona@example.com"
              required
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.emailError">{{ validate.emailError }}</p>
        </div>

        
        <!-- celular -->
        <div class="vx-col sm:w-1/2 mt-5">
          <label for="cellphone" class="vs-input--label mb-2">{{ this.$t('Telefono') }}</label>

          <div class="flex">
            <!-- <div class="card flags sm:w-1/5 xs:w-2/4">
              <img :src="require('@/assets/images/flags/br.png')" class="rounded-full inline-block align-middle" width="32px" />&nbsp;
              <span class="inline-block align-middle">+55</span>
            </div> -->
            <vs-input
              id="cellphone"
              name="cellphone"
              required
              v-model="user.cell_phone"
              class="w-full flag_input"
            />
          </div>
        </div>

        <!-- contraseña -->
        <div class="vx-col sm:w-1/2 mt-5">
          <label for="password">
            <b>{{ this.$t('Contraseña') }}</b>
            <i class="text-xs">&nbsp;&nbsp;({{ this.$t('Mínimo 6 caracteres 1 Mayúscula y/o Símbolo') }})</i>
          </label>

          <vx-input-group>
            <vs-input
              id="password"
              name="password"
              :type=" isVisiblePassword ? 'text' : 'password' "
              :danger="!!validate.passwordError"
              v-model="user.password"
            />

            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  type="flat"
                  style="color: brown; font-size: large !important;"
                  :color=" isVisiblePassword ? 'primary' : 'white'"
                  icon-pack="feather"
                  :icon=" isVisiblePassword ? 'icon-unlock' : 'icon-lock'"
                  @click="isVisiblePassword = !isVisiblePassword"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.passwordError">{{ validate.passwordError }}</p>
        </div>
      </div>
      <h3 class="text-info mt-10">{{ this.$t('Asignación de Clientes') }}</h3>
      <vs-divider class="mb-6"/>
        <label for="cellphone" class="labelsubt ml-5">{{ this.$t('Selecciona los grupos que deseas asociar el cliente:') }}</label>
        <div class="vx-row">
          
            <div class="vx-col w-1/2 mt-5">
              <vx-card class="selected-clients-card">
        
                <div class="col-span-2">
                  <div class="h-12 border-1 border-solid rounded-full" style="background-color:white; border-radius: 9px;" @click="$refs['users-search'].focus()">
                    <div class="flex h-full">
                        <feather-icon :icon="'SearchIcon'" class="mt-2 mb-2 ml-2" />
                        <input
                            @input="searchAll($event.target.value)"
                            class="h-full w-full border-none mr-5"
                            ref="users-search"
                            v-model="search"
                        />
                      </div>
                  </div>
                </div>
                <div class="mt-4">
                  <vs-table
                    style="height: 300px !important; overflow-y: auto;"
                    class="clients-table"
                    :data="clientsList"
                    :noDataText="$t('Datos no disponibles')"
                  >
                    
                    <template>
                      
                      <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in clientsList">
                        <!-- Nombre -->
                        <vs-td style="border-bottom: 3px solid #f8f8f8;padding: 8px 15px;">
                          <div class="vx-row" @click="select_client(tr)" >
                            <div class="vx-col w-11/12 mt-2 ml-5">
                              <h5>Cliente: {{tr.name}}</h5>
                              <p>CNPJ: {{tr.id}}</p>
                            </div>
                          </div>
                        </vs-td>

                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </vx-card>
            </div>
            <div class="vx-col w-1/2 mt-5">
              <vx-card class="selected-clients-card">
                <div class="col-span-2">
                  <div class="h-12 rounded-full" style="background-color:white; border-radius: 9px;" @click="$refs['users-search'].focus()">
                    <div class="flex h-full pt-2">
                      <h5 for="promotion-clients" style="color:#636363">{{ $t("Clientes asociados") }}:</h5>    
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <vs-table
                    style="height: 300px !important; overflow-y: auto;"
                    class="clients-table"
                    :data="selectedList"
                    :noDataText="$t('Datos no disponibles')"
                  >
                    <template>
                      <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in selectedList">
                        <!-- Nombre -->
                        <vs-td style="border-bottom: 3px solid #f8f8f8;padding: 3.5px 15px;">
                          <div class="vx-row" >
                            <div class="vx-col w-8/12 mt-2 ml-5 mb-1">
                              <h5>Cliente: {{tr.name}}</h5>
                              <p>CNPJ: {{tr.id}}</p>
                            </div>
                            <div class="vx-col w-3/12 mt-2">
                              <vs-button color="transparent" @click.native="delete_client(tr)">
                                <feather-icon icon="Trash2Icon" style="color:black !important" class="inline-block align-middle"></feather-icon>
                              </vs-button>
                            </div>
                            
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </vx-card>
            </div>

        </div>
      <!-- Guardar datos boton -->
      <div class="vx-row">
        <div class="w-full mt-10">
            <vs-button
                class="ml-10 vs-component vs-button rounded-full text-black w-1/6 float-right vs-button-relief"
                style="border: 2px solid red"
                color="white"
                @click="toggleRoute()"
              >
                {{ this.$t('Cancelar') }}
              </vs-button>
          <button
            type="submit"
            class="mr-10 vs-component vs-button vs-button-primary vs-button-relief w-1/6 rounded-full float-right"
          >
          {{ this.$t('Guardar') }}
          </button>
        </div>
      </div>
    </form>
  </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select';

import { Validation } from '../../../plugins/validation.js';
import AuthService from '../../../auth/authService.js';

export default {
  data() {
    return {
      validate           : new Validation(),
      isVisiblePassword  : false,
      isVisiblePassword2 : false,
      select_all_stores  : false,
      rol                : [],
      clientsList        : [],
      all                : [],
      search             : '',
      selectedList       : [],

    }
  },

  async created() {
    this.$store.dispatch('updatePageTitle', {
        title: this.$t('Crear Consumidor'),
        subtitle: this.$t('Ingresa la información del nuevo consumidor'),
    });
    
    this.$vs.loading();

    this.findClients();
        
    this.$vs.loading.close()
    this.clearInputs();
  },

  watch: {
    'user.name'( val ) { this.validate.validateName( val ); },
    'user.cell_phone'(val) { this.validate.validatePhone( val ); },
    'user.email'(val) { this.validate.validateEmail( val ); },
    'user.password'(val) { this.validate.validatePassword( val ); },
  },
  methods: {
    toggleRoute() {
        this.$router.push({ path : '/app/consumers/list' });
    },
    async saveUser() {
      const isValidName           = this.validate.validateName( this.user.name );
      const isValidPhone          = this.validate.validatePhone( this.user.cell_phone );
      const isValidEmail          = this.validate.validateEmail( this.user.email );
      const isValidPwd            = this.validate.validatePassword( this.user.password );
      if (this.selectedList == 0){
        this.$vs.notify({
          title: this.$t('Alerta'),
          text: this.$t('Ingrese los clientes asociados al consumidor'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });
        return;
      }
      if(
        !isValidName ||
        !isValidPhone ||
        !isValidEmail ||
        !isValidPwd  ||
        this.selectedList.length == 0
      ) {
        this.$vs.notify({
            title: this.$t('Alerta'),
            text: this.$t('Valide los campos antes de continuar'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
        });
        return;
      }

      this.$vs.loading();
      let clients = []
      this.selectedList.forEach( key => {
        clients.push(key.id)
      });
      const payload = {
        phone_number    : this.user.cell_phone,
        phone           : this.user.cell_phone,
        password        : this.user.password,
        name            : this.user.name,
        email           : this.user.email,
        id_clients      : clients
      }
      const res = await this.$store.dispatch( 'clients/userConsumers', payload );
      this.$vs.loading.close();
        
      if( res ) {
        this.clearInputs();
        this.$router.push({ path: '/app/consumers' });
      }
    },

    searchAll(e){
      const val = e.toLowerCase();
      const temp = this.all.filter(function (d) {
        if (d.name !== null && d.id !== null) {
          return (d.name.toLowerCase().indexOf(val) !== -1 || d.id.toLowerCase().indexOf(val) !== -1) || !val;
        } else {
          return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        }
      });
      this.clientsList = temp;
    },

    select_client(val) {
      if ( !this.selectedList.includes(val) ){
        this.selectedList.push(val)
        let index = this.clientsList.indexOf(val)
        this.clientsList.splice(index, 1)
      }
    },
    delete_client(val) {
      this.clientsList.push(val)
      let index = this.selectedList.indexOf(val)
      this.selectedList.splice(index, 1)
    },
    generatePassword() {
      let res = '';
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+*<>_-)(/&%$#!';
      const charsLenght = chars.length;

      for(let i = 0; i < 8; i++) {
        res += chars.charAt( Math.floor ( Math.random() * charsLenght) );
      }

      this.user.password = res;
      const isValidPassword = this.validate.validatePassword( res );
        
      if(!isValidPassword) {
        this.user.password = '';
        this.generatePassword();
        return;
      }

      this.user.password = res;
      this.user.password2 = res;
      this.validate.validatePassword2( res, res );
      this.isVisiblePassword2 = true;

      if( !navigator.clipboard ) {
        this.$vs.notify({
          title: this.$t('Error'),
          text: this.$t('No se pudo copiar la contraseña'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          time: 7000,
        });

      } else {
        navigator.clipboard.writeText(this.user.password);
        this.$vs.notify({
          title: this.$t('Correcto'),
          text: this.$t('Contraseña copiada'),
          iconPack: 'feather',
          icon: 'icon-smile',
          color: 'success',
          time: 7000
        });
      }

      this.isVisiblePassword2 = false;

    },

    clearInputs() {
      this.user.name           = '';
      this.user.cell_phone     = '';
      this.user.email          = '';
      this.user.password       = '';
      this.user.position       = '';
    },

    async findClients() {
      this.loading = true
      const results = await this.$store.dispatch('clients/UserClientList');
      
      if( !results ) this.loading = false;
      else {
        this.clientsList = results.data
        this.all = results.data
        this.loading = false;
        this.$vs.loading.close();
      }

      
  },
  },

  computed: {
    superAdminAccess() { return AuthService.isValidModule( 'OWNER' ); },
    userStatus() {       return this.$store.state.users.userStatus; },
    user: {
      get() { // user proxy
        const store = this.$store;
        const user = store.state.users.user;
        let userProxy = new Proxy( user, {
          set (obj, key, value) {
            store.commit('users/SET_USER', {
              ...obj,
              [key]: value,
            });    
            return true
          }
        });
        return userProxy;
      }
        
    },
    callsigns() {
      const callsigns = this.$store.state.location.callsigns;
      const signs = callsigns.map(sign => {
        let ob = Object.assign({}, sign);
        ob.sign = `+${sign.callingCodes[0]}`;
        return ob;
      });

      return signs;
    },
  },

  components: { 'v-select': vSelect, }
}
</script>

<style lang="css" scoped>
  input#pwd{ display: none; }
  .flags{
    padding: 7px;
    border: 1px #dededf solid;
    /* border-radius: 0px; */
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .flag_input{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .input-select-label-primary--active{
    color: rgb(98 98 98) !important;
  }

  .selected-clients-card{
    border: 1px solid #c2bfbf;
  }

  .border-1{
    border: 1px solid #c2bfbf !important;
    border-radius: 9px !important;
  }

  .vs-table-content{
    height: 300px !important;
  }

  #templ{
    height: 300px !important;
    
  }

</style>
